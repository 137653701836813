import React from "react";
import { NavLink } from "react-router-dom";
import { Table } from "./Leaderboard";
import axios from "axios";
import { CONFIG } from "config/ApplicationConfig";

const AdminInterfaceLeaderboard = () => {
  const mainTypes = [
    "Basic",
    "Calculation",
    "Ordering",
    "Linking",
    "Question Bombardment",
  ]; // type
  const subTypes = ["Blockchain and Cryptocurrency", "Trading", "Economics"]; // category

  const SUBCATEGORIES = [
    // subcategory
    "Cryptocurrency Basics",
    "Blockchain Technology",
    "Altcoins",
    "Cryptocurrency Exchanges",
    "Wallets and Storage",
    "ICOs and Token Sales",
    "Decentralized Finance (DeFi)",
    "NFTs (Non-Fungible Tokens)",
    "Cryptocurrency Regulations",
    "Security and Privacy",
    "Cryptocurrency Mining",
    "Stablecoins",
    "Layer 2 Solutions and Scaling",
    "Smart Contracts",
    "Cryptocurrency Use Cases and Adoption",
    "Cryptocurrency History and Milestones",
    "Influential Figures in the Cryptocurrency Space",
    "Cryptocurrency Taxation",
    "Central Bank Digital Currencies (CBDCs)",
    "Crypto Lending Platforms",
    "Cryptocurrency Staking and Yield Farming",
    "Cross-Chain Technologies",
    "Privacy Coins",
    "Cryptocurrency Forks",
    "Cryptocurrency Market Analysis",
    "Blockchain Consensus Mechanisms",
    "Cryptocurrency Airdrops and Swaps",
    "Social Impact of Cryptocurrencies",
    "Tokenomics and Token Models",
    "Crypto-backed Loans",
    "Decentralized Exchanges (DEXs)",
    "Crypto Wallet Types",
    "Blockchain Interoperability",
    "Cryptocurrency Trading Bots",
    "Blockchain Gaming and Virtual Worlds",
    "Decentralized Autonomous Organizations (DAOs)",
    "Cryptocurrency Community and Culture",
    "On-chain Analytics",
    "Crypto Payment Solutions",
    "Blockchain and IoT (Internet of Things)",
    "Blockchain and AI (Artificial Intelligence)",
    "Sidechains and Off-chain Solutions",
    "Cryptocurrency Derivatives",
    "Decentralized Identity Solutions",
    "Oracle Services",
    "Blockchain Development Platforms",
    "Asset Tokenization",
    "Decentralized Cloud Storage",
    "Crypto Ecosystem Infrastructure",
    "Security Token Offerings (STOs)",
    "Crypto Insurance",
    "Blockchain and Supply Chain Management",
    "Crypto Philanthropy and Grants",
    "Blockchain in Healthcare",
    "Blockchain in Education",
    "Blockchain in Real Estate",
    "Domain-specific Blockchains",
    "Technical Analysis",
    "Fundamental Analysis",
    "Sentiment Analysis",
    "Risk Management",
    "Trading Psychology",
    "Trading Strategies",
    "Chart Patterns",
    "Indicators and Oscillators",
    "Timeframes",
    "Order Types",
    "Trading Platforms and Tools",
    "Cryptocurrency Exchanges",
    "Margin Trading",
    "Leverage and Liquidation",
    "Market Structure",
    "Market Cycles",
    "Portfolio Management",
    "Asset Allocation",
    "Diversification",
    "Trading Pairs",
    "Trading Bots and Algorithmic Trading",
    "Arbitrage Trading",
    "Day Trading",
    "Swing Trading",
    "Scalping",
    "Position Trading",
    "Trend Trading",
    "Breakout Trading",
    "Support and Resistance Levels",
    "Fibonacci Retracements and Extensions",
    "Moving Averages",
    "RSI (Relative Strength Index)",
    "MACD (Moving Average Convergence Divergence)",
    "Ichimoku Cloud",
    "Bollinger Bands",
    "Elliot Wave Theory",
    "Candlestick Patterns",
    "Trading Journals and Record Keeping",
    "Tax Implications of Trading",
    "Trading Regulations and Compliance",
    "Supply and Demand",
    "Inflation",
    "Interest Rates",
    "Opportunity Cost",
    "Money Supply",
    "Quantitative Easing",
    "Inflation Targeting",
    "Stock Market Indices",
    "Commodities",
    "Bonds",
    "Derivatives",
    "Foreign Exchange Market",
    "Currency Pairs",
    "Exchange Rate Systems (Floating, Fixed, Pegged)",
    "Government Spending",
    "Taxation",
    "Budget Deficits and Surpluses",
    "Public Debt",
    "Gross Domestic Product (GDP)",
    "Unemployment Rate",
    "Consumer Price Index (CPI)",
    "Producer Price Index (PPI)",
    "Balance of Trade",
    "Trade Agreements",
    "Import and Export",
    "Protectionism",
    "Perfect Competition",
    "Monopoly",
    "Oligopoly",
    "Monopolistic Competition",
    "Capitalism",
    "Socialism",
    "Mixed Economy",
    "Communism",
    "Economic Growth Theories",
    "Human Development Index (HDI)",
    "Income Inequality",
    "Sustainable Development Goals (SDGs)",
    "Labor Force Participation Rate",
    "Types of Unemployment",
    "Minimum Wage",
    "Labor Unions",
    "Social Security",
    "Healthcare",
    "Education",
    "Public Infrastructure",
    "Saving and Investing",
    "Retirement Planning",
    "Credit and Loans",
    "Insurance",
  ];

  const columns = ["Rank", "Player", "Submitted Qs", "Accepted Qs", "Credit"];

  const [mainType, setMainType] = React.useState("Basic");
  const [subType, setSubType] = React.useState("Blockchain and Cryptocurrency");
  const [subCategory, setSubCategory] = React.useState("Cryptocurrency Basics");

  const [startDate, setStartDate] = React.useState("x/x/x");
  const [endDate, setEndDate] = React.useState("x/x/x");

  const [data, setData] = React.useState([]);

  return (
    <div className="w-full h-full flex justify-center flex-col items-center">
      <div className="bg-mainBg border-shadow h-2/3 flex-col w-1/2 flex justify-between items-center rounded-md py-4">
        <NavLink
          to={"/home"}
          className="absolute flex items-center justify-center py-1 pl-2 border-2 border-black rounded-md cursor-pointer bg-itemBg top-4 left-6"
        >
          <span class="material-symbols-outlined">arrow_back_ios</span>
        </NavLink>

        <div className="flex flex-col gap-2">
          <div className="flex gap-2">
            {mainTypes.map((type) => {
              return (
                <div
                  className={`cursor-pointer ${
                    mainType == type ? "bg-activeBg" : "bg-itemBg"
                  } p-2 rounded-md text-lg border-black border-2`}
                  onClick={() => setMainType(type)}
                >
                  {type}
                </div>
              );
            })}
          </div>
          <div className="flex rounded-t-md items-center justify-center">
            {subTypes.map((MsubType) => {
              return (
                <div
                  className={`cursor-pointer flex-1 h-full ${
                    MsubType == subType ? "bg-activeBg" : "bg-itemBg"
                  } p-2 rounded-t-md border-black border-2 text-lg`}
                  onClick={() => setSubType(MsubType)}
                >
                  {MsubType}
                </div>
              );
            })}
          </div>
          <div className="max-h-32 min-h-32 overflow-scroll flex flex-col gap-1 bg-white -mt-2">
            {SUBCATEGORIES.map((category) => {
              return (
                <div
                  className={`cursor-pointer text-lg ${
                    category == subCategory ? "text-activeBg" : "text-black"
                  }`}
                  onClick={() => setSubCategory(category)}
                >
                  {category}
                </div>
              );
            })}
          </div>
          <div className="flex gap-4 items-center">
            <DateInput date={startDate} setDate={setStartDate} />
            <div className="px-4 text-xl text-black flex font-bold">-</div>
            <DateInput date={endDate} setDate={setEndDate} />
          </div>

          <div
            className="cursor-pointer p-2 bg-itemBg rounded-md border-2 border-black"
            onClick={async () => {
              console.log(mainType, subType, subCategory, startDate, endDate);
              const response = await axios.post(
                `${CONFIG.API_URL}/web/admin-leaderboard`,
                {
                  mainType,
                  subType,
                  subCategory,
                  startDate,
                  endDate,
                },
                {
                  withCredentials: true,
                }
              );

              setData(response.data.data);
            }}
          >
            Search
          </div>
        </div>
        <Table
          columns={columns}
          data={{
            leaderboard: data,
          }}
          isSelfNeeded={false}
        />
      </div>
    </div>
  );
};

const DateInput = ({ date, setDate }) => {
  const handleInputChange = (index, value) => {
    const dateArray = date.split("/");
    dateArray[index] = value || "x";
    setDate(dateArray.join("/"));
  };

  return (
    <div className="flex gap-2">
      <input
        type="number"
        className="bg-itemBg border-black border-2 outline-none p-2 text-lg rounded-md"
        placeholder="Year"
        maxLength={4}
        onChange={(e) => handleInputChange(0, e.target.value)}
        value={date.split("/")[0] === "x" ? "" : date.split("/")[0]}
      />
      <input
        type="number"
        className="bg-itemBg border-black border-2 outline-none p-2 text-lg rounded-md"
        placeholder="Month"
        min="1"
        max="12"
        maxLength={2}
        onChange={(e) => handleInputChange(1, e.target.value)}
        value={date.split("/")[1] === "x" ? "" : date.split("/")[1]}
      />
      <input
        type="number"
        className="bg-itemBg border-black border-2 outline-none p-2 text-lg rounded-md"
        placeholder="Day"
        min="1"
        max="31"
        maxLength={2}
        onChange={(e) => handleInputChange(2, e.target.value)}
        value={date.split("/")[2] === "x" ? "" : date.split("/")[2]}
      />
    </div>
  );
};

export default AdminInterfaceLeaderboard;
