import React from "react";
import { NavLink } from "react-router-dom";
import useApplicationState from "state/ApplicationState";

const UserSelectionPage = () => {
  const state = useApplicationState();

  return (
    <div className="flex items-center justify-center w-full h-full">
      <div className="flex items-center justify-center w-full m-5 lg:w-1/2 xl:w-1/4 h-1/2 bg-mainBg border-shadow">
        <div className="flex flex-col items-center justify-center w-3/4 h-3/4">
          <SelectionButton
            text="Submit Quiz Content"
            link={"/question-category"}
          />
          <SelectionButton text="Leaderboard" link={"/leaderboard"} />
          {state.user.role === "ADMIN" ? (
            <SelectionButton text="Admin Interface" link={"/admin"} />
          ) : null}
          {state.user.role === "ADMIN" ? (
            <SelectionButton
              text="Admin Leaderboard"
              link={"/admin-leaderboard"}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default UserSelectionPage;

const SelectionButton = (props) => {
  const { text, link } = props;

  return (
    <div className="flex items-center justify-center w-3/4 h-1/2">
      <NavLink
        to={link}
        className="flex items-center justify-center w-full p-2 transition-all duration-200 border-2 border-black cursor-pointer h-2/4 bg-itemBg border-shadow hover:bg-opacity-90"
      >
        <p className="text-xl text-center text-black">{text}</p>
      </NavLink>
    </div>
  );
};
